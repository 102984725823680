const { doRecaptchaAction } = require('../util/util');
export const OrderJourneyTypes = {
  default: 'default',
  sales: 'sales',
};

export const OrderJourneyAuthModes = {
  apiKey: 'apiKey',
  recaptcha: 'recaptcha',
  bearer: 'bearer',
};

let bearerToken = '';
export const setBearerToken = (token) => (bearerToken = token);

export const getOrderJourneyOptions = (journeyType) => {
  switch (journeyType) {
    case OrderJourneyTypes.sales:
      return {
        authModes: ['bearer'],
        apiRoute: 'internal/sales/',
        newOrderUrl: '/internal/sales/order/new/',
      };
    default:
      return {
        authModes: ['apiKey', 'recaptcha'],
        newOrderUrl: '/secure-order/new/',
      };
  }
};

export const startNewOrder = async (
  uprn,
  productCode,
  aklamioTransactionId,
  journeyType,
  oneTouchSwitchData,
  providerEnum = 0, // 0 = Lit Fibre, 1 = City Fibre
) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + 'order', {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uprn,
        productCode,
        aklamioTransactionId,
        oneTouchSwitchData,
        providerEnum,
      }),
    });
  }, authModes);
};

export const getDisplayOrder = async (activeOrder, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}`, {
      headers,
    });
  }, authModes);
};

export const getPromoCodes = async (activeOrder, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}/promotions`, {
      headers,
    });
  }, authModes);
};

export const applyPromoCode = async (activeOrder, promoCode, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(
      getApiUrl(apiRoute) +
        `order/${activeOrder}/promotions/${encodeURIComponent(promoCode)}`,
      { method: 'POST', headers },
    );
  }, authModes);
};

export const setChildProductQuantity = async (
  activeOrder,
  parentProductId,
  productCode,
  quantity,
  journeyType,
) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}/children`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        parentProductId,
        productCode,
        quantity,
      }),
    });
  }, authModes);
};

export const updateAccount = async (activeOrder, formData, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}/account`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
  }, authModes);
};

export const checkout = async (
  activeOrder,
  acceptedTermsAndConditions,
  notes,
  journeyType,
) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}/checkout`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ acceptedTermsAndConditions, notes }),
    });
  }, authModes);
};

export const confirmOrder = async (activeOrder, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${activeOrder}/confirm`, {
      method: 'POST',
      headers,
    });
  }, authModes);
};

export const getProductAvailability = async (uprn, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `availability-check/${uprn}`, {
      headers,
    });
  }, authModes);
};

export const getUserData = async (journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + 'user', {
      headers,
    });
  }, authModes);
};

export const getAppointment = async (activeOrder, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(
      getApiUrl(apiRoute) + `portal/appointment/order/${activeOrder}`,
      {
        headers,
      },
    );
  }, authModes);
};
export const getSurveyNetworkNotes = async (uprn, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `snn/${uprn}`, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    });
  }, authModes);
};

export const getAppointmentSlots = async (activeOrder, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(
      getApiUrl(apiRoute) + `portal/appointment/order/${activeOrder}/slots`,
      {
        headers,
      },
    );
  }, authModes);
};

export const bookAppointment = async (activeOrder, slot, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(
      getApiUrl(apiRoute) + `portal/appointment/order/${activeOrder}`,
      {
        method: 'POST',
        body: JSON.stringify(slot),
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
      },
    );
  }, authModes);
};

export const patchUserData = async (patchRequest, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + 'user', {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(patchRequest),
    });
  }, authModes);
};

export const getExistingLead = async (uprn, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `existing-lead/${uprn}`, {
      headers,
    });
  }, authModes);
};
export const getDuplicateOrder = async (uprn, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `duplicate-order/${uprn}`, {
      headers,
    });
  }, authModes);
};
const getApiUrl = (apiRoute) => {
  return process.env.GATSBY_ORDER_API_ADDRESS + (apiRoute ?? '');
};

export const getDemographicData = async (uprn, journeyType) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(
      getApiUrl(apiRoute) + `availability-check/demographic/${uprn}`,
      {
        headers,
      },
    );
  }, authModes);
};

export const registerOneTouchSwitch = async (
  id,
  oneTouchSwitchData,
  journeyType,
) => {
  const { apiRoute, authModes } = getOrderJourneyOptions(journeyType);
  return executeOrderAction((headers) => {
    return fetch(getApiUrl(apiRoute) + `order/${id}/ots`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(oneTouchSwitchData),
    });
  }, authModes);
};

const executeOrderAction = async (action, authModes) => {
  const headers = {};
  if (authModes.some((mode) => mode === OrderJourneyAuthModes.apiKey))
    headers['ApiKey'] = process.env.GATSBY_ORDER_API_KEY;
  if (authModes.some((mode) => mode === OrderJourneyAuthModes.bearer))
    headers['Authorization'] = `Bearer ${bearerToken}`;

  if (authModes.some((mode) => mode === OrderJourneyAuthModes.recaptcha))
    return await doRecaptchaAction((token) => {
      headers['Captcha-Token'] = token;
      return action(headers);
    }, '/');

  return action(headers);
};
