import React from 'react';

const Toggle = ({
  name,
  onToggledValue,
  offToggledValue,
  isToggled,
  setIsToggled,
}) => {
  return (
    <div className="toggle-container" onClick={() => setIsToggled(!isToggled)}>
      <p>{isToggled ? onToggledValue : offToggledValue}</p>
      <div className={`toggle `}>
        <p className={`${isToggled && 'toggle-on'}`}>Yes</p>
        <p className={`${!isToggled && 'toggle-on'}`}>No</p>
      </div>
    </div>
  );
};

export default Toggle;
