import React, { useEffect, useState, useRef, useContext } from 'react';
import Modal from './Modal';
import ContactUsForm from './ContactUsForm';
import LitImage from './images/LitImage';
import ActivityContext from '../context/ActivityContext';
import dataLayer from './DataLayer';
const ContactUsLinkModal = ({
  isButton,
  isLink,
  isBlock,
  text,
  image,
  pathColour,
}) => {
  const [dialogShow, setDialogShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const { contactSubmitted, setContactSubmitted } = useContext(ActivityContext);
  const modal = useRef(null);

  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog.showModal();
      setAnimation('open-modal');
      dataLayer({ event: 'considered_human_help' });
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
    }
  };

  useEffect(() => {
    const dialog = modal.current;
    contactSubmitted &&
      dialog &&
      setTimeout(() => {
        dialog.close();
        setAnimation('close-modal');
      }, 1500);
  }, [contactSubmitted]);

  return (
    <div className="contact-us-link-container">
      <button
        type="button"
        onClick={() => switchDialog()}
        className={`${isButton && `square-button-${pathColour}`} ${
          isLink && `${pathColour}-button-underline`
        } ${pathColour} path`}
      >
        {image && (
          <div className="image">
            <LitImage image={image} />
          </div>
        )}
        {text}
      </button>

      <Modal
        animation={animation}
        dialogShow={dialogShow}
        modal={modal}
        switchDialog={switchDialog}
      >
        <ContactUsForm
          submitted={contactSubmitted}
          setSubmitted={setContactSubmitted}
          isHumanHelp={true}
          overrideSubject={'sales'}
        />
      </Modal>
    </div>
  );
};

export default ContactUsLinkModal;
